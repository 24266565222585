<template>
  <v-hover v-slot="{ hover }">
    <v-card
      @click="$router.push(`/products/${product.id}`)"
      link
      flat
      style="transition: all 0.2s"
      :class="hover ? 'elevation-15' : ''"
      :min-height="minHeight"
      max-width="275"
    >
      <v-img
        :src="currentImage"
        :min-height="minHeight - 50"
        @mouseover="changeImage(true)"
        @mouseleave="changeImage(false)"
        contain
      />
      <v-divider />
      <v-card-text class="text-center font-weight-bold product-text pb-1">
        {{ product.product_name }}
      </v-card-text>

      <!--------------------------------------------------------------------- 
                Funcionalidade de avaliação de produto temporariamente desabilitada START
             ----------------------------------------------------------------------->
      <!-- <v-card-actions class="pt-0">
                <v-spacer/>
                <v-item-group v-model="product.stars" multiple>
                    <span v-for="n in 5" :key="n">
                        <v-item v-slot="{ active }">
                            <v-icon color="primary" class="mx-1" small>
                                {{ active ? 'fas fa-star' : 'far fa-star' }}
                            </v-icon>
                        </v-item>
                    </span>
                </v-item-group>
                <v-spacer/>
            </v-card-actions> -->
      <!--------------------------------------------------------------------- 
                Funcionalidade de avaliação de produto temporariamente desabilitada FINISH
             ----------------------------------------------------------------------->
      <span
        v-if="product.category"
        class="card-badge error overline white--text"
        v-text="product.category"
      />
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    minHeight: {
      type: Number,
      String,
      default: () => {
        return 370
      },
    },
  },
  data: () => ({
    currentImageIndex: 0,
  }),
  computed: {
    currentImage() {
      return (
        this.$store.state.imagePath +
        this.product.product_images[this.currentImageIndex].src
      )
    },
  },
  methods: {
    changeImage(isHovering) {
      if (isHovering && this.product.product_images.length > 1) {
        this.currentImageIndex = 1
      } else {
        this.currentImageIndex = 0
      }
    },
  },
}
</script>

<style>
.product-text {
  line-height: 15px;
}
.card-badge {
  border-radius: 0px 50px 50px 0 !important;
  line-height: 1.2rem !important;
  padding: 0 25px;
  position: absolute;
  top: 20px;
}
</style>
